import { IAnimationStateListener, Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Ticker } from 'pixi.js';

import SlotMachine from '..';
import {
  aBaseGameWaitLoop1,
  bBonusWinReaction,
  cWinReaction,
  dBigWinReaction,
  eFreeSpinGameExpand,
  eFreeSpinGameWaitLoop,
  gTotalWinUnderReaction,
  hLotSelect,
  hTotalWinOverReaction,
} from '../../anticipation/table';
import { getResultFromTable } from '../../anticipation/util';
import { red } from '../../config';
import { BetBonusReward, EventTypes, GameMode, ISettledBet, UserBonus } from '../../global.d';
import { setBetAmount, setBrokenBuyFeature, setBrokenGame, setFreeSpinsTotalWin, setGameMode } from '../../gql/cache';
import { getWinCoin, isBaseGameMode, isFreeSpinsMode, isMobileDevice, isMotionLoop } from '../../utils/helper';
import { debugDisplay } from '../../utils/utils';
import Animation from '../animations/animation';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import {
  BaseGameMotionType,
  MotionMimamori,
  MotionName,
  MotionReactionIn,
  MotionTaiki,
  motionList,
} from '../avatarMotion/config';
import {
  AVATAR_LANDSCAPE_PC_POS_X,
  AVATAR_LANDSCAPE_POS_X,
  AVATAR_LANDSCAPE_POS_Y,
  AVATAR_LANDSCAPE_SCALE,
  AVATAR_LOT_LANDSCAPE_POS_X,
  AVATAR_LOT_PORTRAIT_POS_Y,
  AVATAR_MOVE_DURATION,
  AVATAR_PORTRAIT_MOBILE_POS_Y,
  AVATAR_PORTRAIT_POS_X,
  AVATAR_PORTRAIT_POS_Y,
  AVATAR_PORTRAIT_SCALE,
  SlotMachineState,
  WinStages,
  Z_INDEX_AVATAR,
  eventManager,
} from '../config';

class VAvatar extends Spine {
  private motionType: BaseGameMotionType;

  private motionTypeNext: BaseGameMotionType;

  private MotionWait: Animation | undefined;

  private motion: number;

  private delay: Animation | undefined;

  private timeId: NodeJS.Timeout[] = [];

  private isIdleMotion: boolean;

  private voiceComplete = false;

  private baseGameIdle: number;

  private baseGameIdleReSet: boolean;

  private isLandScape = true;

  private isLot = false;

  private isWinPostIdleLot = false;

  private preGameMode: GameMode | undefined;

  constructor() {
    super(PIXI.Loader.shared.resources['avatar_Mr.First']!.spineData!);

    this.isIdleMotion = false;
    this.motion = 0;
    this.baseGameIdle = 0;
    this.baseGameIdleReSet = true;
    this.preGameMode = undefined;

    this.motionType = BaseGameMotionType.Normal;
    this.motionTypeNext = BaseGameMotionType.Non;

    this.preGameMode = setGameMode();
    debugDisplay('=======this.preGameMode', this.preGameMode);

    this.avatarMotionMain(0);

    const motion = this.lotBaseGameWaitLoop(true);

    let motionName = 'idle_01_a';
    switch (motion) {
      case MotionName.TAIKI1:
        motionName = 'idle_01_a';
        break;
      case MotionName.TAIKI2:
        motionName = 'idle_02_a';
        break;
      case MotionName.TAIKI3:
        motionName = 'idle_03_a';
        break;
      case MotionName.TAIKI4:
        motionName = 'idle_04_a';
        break;
      case MotionName.TAIKI5:
        motionName = 'idle_05_a';
        break;
    }

    this.state.setAnimation(0, motionName, false);
    this.position.x = AVATAR_LANDSCAPE_POS_X;
    this.position.y = AVATAR_LANDSCAPE_POS_Y;

    this.zIndex = Z_INDEX_AVATAR;

    if (setBrokenGame()) {
      this.baseGameIdleReSet = true;
    }

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.on(EventTypes.BONUS_WIN, () => {
      this.motionType = BaseGameMotionType.BonusWin;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.TOTAL_WIN_DISPLAY_AVATAR, () => {
      this.motionType = BaseGameMotionType.TotalWin;
      this.avatarMotionMain(0);
    });

    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_START, this.countUpStart.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.SP_SYMBOL_LOT_START, this.onSceneChange.bind(this));
    eventManager.addListener(EventTypes.FREE_SPINS_DISPLAY_FLASH, this.onFreeSpinsDisplayFlash.bind(this));
    eventManager.addListener(EventTypes.SP_SYMBOL_IF_VISIBLE, this.setAvatarPosition.bind(this));
    eventManager.addListener(EventTypes.UPDATE_TOTAL_WIN_VALUE, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.LOT_CONFIRMED, this.onLotConfirmed.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
  }

  private setAvatarPosition(): void {
    const animation = new AnimationGroup();
    if (this.isLandScape) {
      animation.addAnimation(this.getSceneChangeOutAnimationX(AVATAR_MOVE_DURATION));
    } else {
      animation.addAnimation(this.getSceneChangeOutAnimationY(AVATAR_MOVE_DURATION));
    }
    animation.start();
    this.isLot = false;
  }

  private getSceneChangeOutAnimationX(duration: number): Animation {
    const targetPositionX = isMobileDevice() ? AVATAR_LANDSCAPE_POS_X : AVATAR_LANDSCAPE_PC_POS_X;
    const animation = new Tween({
      object: this,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: this.position.x,
      target: targetPositionX,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => 1 - Math.pow(1 - n, 7),
    });
    animation.addOnComplete(() => {
      if (this.isLandScape) {
        this.position.x = isMobileDevice() ? AVATAR_LANDSCAPE_POS_X : AVATAR_LANDSCAPE_PC_POS_X;
      } else {
        this.position.x = AVATAR_PORTRAIT_POS_X;
      }
    });
    return animation;
  }

  private getSceneChangeOutAnimationY(duration: number): Animation {
    const targetPositionY = isMobileDevice() ? AVATAR_PORTRAIT_MOBILE_POS_Y : AVATAR_PORTRAIT_POS_Y;
    const animation = new Tween({
      object: this,
      duration,
      property: TweenProperties.Y,
      propertyBeginValue: this.position.y,
      target: targetPositionY,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => 1 - Math.pow(1 - n, 7),
    });
    animation.addOnComplete(() => {
      if (this.isLandScape) {
        this.position.y = AVATAR_LANDSCAPE_POS_Y;
      } else {
        this.position.y = isMobileDevice() ? AVATAR_PORTRAIT_MOBILE_POS_Y : AVATAR_PORTRAIT_POS_Y;
      }
    });
    return animation;
  }

  private onSceneChange(): void {
    this.isLot = true;
    if (this.isLandScape) {
      this.position.x = AVATAR_LOT_LANDSCAPE_POS_X;
    } else {
      this.position.y = AVATAR_LOT_PORTRAIT_POS_Y;
    }

    this.motionType = BaseGameMotionType.LotSelect;
    this.avatarMotionMain(0);
  }

  private onFreeSpinsDisplayFlash(): void {
    this.visible = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isNumber(arg: any): arg is number {
    return typeof arg === 'number';
  }

  private onStateChange(state: SlotMachineState): void {
    if (state === SlotMachineState.JINGLE && this.getBonusFromResult()) {
      if (!isFreeSpinsMode(setGameMode())) {
        // フィーチャートリガー
        debugDisplay('Featureトリガー');
        eventManager.emit(EventTypes.BONUS_WIN);
      }
    }
  }

  private onChangeMode(settings: { mode: GameMode }) {
    debugDisplay('=======setting', this.preGameMode, settings.mode);

    if (this.preGameMode === settings.mode) return;
    this.preGameMode = settings.mode;

    debugDisplay('=======setting', settings.mode);
    if (isFreeSpinsMode(settings.mode) && this.motionType != BaseGameMotionType.FreeSpin) {
      this.avatarMotionMain(0);
    } else if (isBaseGameMode(settings.mode)) {
      this.motionType = BaseGameMotionType.Normal;
      this.avatarMotionMain(0);
    }
  }

  private countUpStart(stage: WinStages, isExpand: boolean, winAmount: number) {
    if (this.motionType === BaseGameMotionType.BonusWin || this.motionType === BaseGameMotionType.BonusWinLoop) return;
    if (winAmount === 0) return;
    if (stage < WinStages.BigWin) {
      this.motionType = BaseGameMotionType.CountUp;
    } else {
      if (isExpand) {
        this.motionType = BaseGameMotionType.BigWinExpand;
      } else {
        this.motionType = BaseGameMotionType.CountUpBig;
      }
    }
    this.avatarMotionMain(0);
  }

  private onCountUpEnd() {
    if (this.motionType === BaseGameMotionType.BonusWin || this.motionType === BaseGameMotionType.BonusWinLoop) return;
    if (isFreeSpinsMode(setGameMode())) {
      // 何もしない
    } else {
      this.motionType = BaseGameMotionType.Normal;
    }

    // TODO カウントアップ終了後、すぐにidolに戻したい場合
    // this.avatarMotionMain(0);
  }

  private onLotConfirmed() {
    this.motionType = BaseGameMotionType.LotConfirmed;
    this.avatarMotionMain(0);
  }

  private onStartSpin() {
    if (setBrokenBuyFeature()) return;

    if (this.isWinPostIdleLot) {
      this.isWinPostIdleLot = false;
    } else {
      if (isBaseGameMode(setGameMode())) {
        this.motionType = BaseGameMotionType.Normal;
      } else {
        this.motionType = BaseGameMotionType.FreeSpin;
      }
      this.baseGameIdleReSet = true;
      this.avatarMotionMain(0);
    }
  }

  private getBonusFromResult(): UserBonus | undefined {
    return (
      SlotMachine.getInstance().nextResult?.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetBonusReward',
      ) as BetBonusReward
    )?.userBonus;
  }

  private avatarSetAnimationStart(motion: string): Animation {
    const animation = new Animation({});
    animation.duration = this.skeleton.data.findAnimation(motion)!.duration * 1000;

    // debugDisplay('== animation.duration', animation.duration, 'motion', motion);

    const loop = motion.includes('idle') || motion.includes('watching_01');
    // const date = new Date();
    // console.log(
    //   '===motion',
    //   motion.includes('idle'),
    //   motion.includes('watching_01'),
    //   '== animation.duration',
    //   animation.duration,
    //   'motion',
    //   motion,
    //   date.toLocaleString(),
    // );

    const listener: IAnimationStateListener = {
      complete: () => {
        Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };
    animation.addOnStart(() => {
      this.state.removeListener(listener);
      this.state.setAnimation(0, motion, loop);
      this.state.addListener(listener);
    });
    animation.addOnSkip(() => {
      this.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.state.removeListener(listener);
    });

    animation.start();

    return animation;
  }

  private avatarSetAnimation(motion: MotionName): void {
    this.timeId.forEach((timer) => {
      clearTimeout(timer);
    });
    this.timeId = [];

    let timer = 0;
    for (let i = 0; i < motionList[motion]!.motion.length; i++) {
      this.timeId[this.timeId.length] = setTimeout(() => {
        // const date = new Date();
        // debugDisplay('=========== date', date.toLocaleString());
        this.avatarSetAnimationStart(motionList[motion]!.motion[i]!);
      }, timer);
      timer += this.skeleton.data.findAnimation(motionList[motion]!.motion[i]!)!.duration * 1000;
    }

    if (!isMotionLoop(this.motionType)) {
      this.timeId[this.timeId.length] = setTimeout(() => {
        this.avatarMotionMain(0);
      }, timer);
    }
  }

  private startWinAnimation(nextResult: ISettledBet | number): void {
    if (this.motionType === BaseGameMotionType.BonusWin || this.motionType === BaseGameMotionType.BonusWinLoop) return;

    let winCoinAmount = 0;
    if (this.isNumber(nextResult)) {
      winCoinAmount = getWinCoin();
    } else {
      winCoinAmount = nextResult.bet.result.winCoinAmount;
    }

    if (
      winCoinAmount > 0 &&
      this.motionType != BaseGameMotionType.CountUp &&
      this.motionType != BaseGameMotionType.CountUpLoop &&
      this.motionType != BaseGameMotionType.CountUpBig &&
      this.motionType != BaseGameMotionType.CountUpBigLoop &&
      this.motionType != BaseGameMotionType.BigWinExpand &&
      this.motionType != BaseGameMotionType.BigWinExpandLoop
    ) {
      this.motionType = BaseGameMotionType.CountUp;
    }
  }

  // TODO　ベースゲームダミー　モーション
  private avatarMotionMain(delay: number): void {
    // const date = new Date();
    // debugDisplay(
    //   '=============avatarMotionMain========== delay',
    //   delay,
    //   date.toLocaleString(),
    //   'this.motionType',
    //   this.motionType,
    // );

    if (this.MotionWait != undefined) {
      this.MotionWait.skip();
      this.MotionWait = undefined;
    }

    // TODO  debugDisplay(red + 'タイマー前', 'delay', delay, date.toLocaleString());
    this.MotionWait = Tween.createDelayAnimation(delay);
    this.MotionWait.addOnComplete(() => {
      // debugDisplay(red + 'タイマー発動', date.toLocaleString());

      if (isFreeSpinsMode(setGameMode())) {
        this.avatarMotionFreeSpinGameMain();
        return;
      }

      const motion = this.baseGameMotionSelection();

      if (this.motionType === BaseGameMotionType.Non) {
        this.avatarMotionMain(0);
        return;
      }

      if (this.delay != undefined) {
        this.delay?.skip();
        this.delay = undefined;
      }

      this.avatarSetAnimation(motion);
    });
    this.MotionWait.start();
  }

  // 3.3.1 ベースゲーム中のモーション選択フロー
  // TODO モーション再生終了イベントを拾ったらここへ来るように
  private baseGameMotionSelection(): number {
    const date = new Date();
    debugDisplay('baseGameMotionSelection', this.motionType, 'date', date.toLocaleString());

    let motion = 0;
    this.isIdleMotion = false;
    this.setDefaultMix(false);

    switch (this.motionType) {
      // B. フィーチャ当選時
      case BaseGameMotionType.BonusWin:
        motion = this.lotBaseGameBonusWin();
        this.motionType = BaseGameMotionType.BonusWinLoop;
        this.motion = motion + 1;
        this.baseGameIdleReSet = true;
        debugDisplay('B. フィーチャ当選時 motion', motion);
        break;

      // B. フィーチャ当選時Loop
      case BaseGameMotionType.BonusWinLoop:
        motion = this.motion;
        this.baseGameIdleReSet = true;
        debugDisplay('B. フィーチャ当選時 LOOP motion', motion);
        break;

      // C. Win当せん時（Big未満）
      case BaseGameMotionType.CountUp: {
        const rand = Math.floor(Math.random() * 100);
        const lot = getResultFromTable(cWinReaction[0]!, rand);
        if (lot === 2 || lot === 4) {
          debugDisplay('ERROR :C. Win当せん時（Big未満）', lot);
        }
        motion = MotionReactionIn[lot]!;
        this.motionType = BaseGameMotionType.CountUpLoop;
        this.motion = motion + 1;
        this.baseGameIdleReSet = true;
        this.isWinPostIdleLot = true;
        debugDisplay('C. Win当せん時（Big未満）IN', motion);
        break;
      }
      // C. Win当せん時（Big未満）LOOP
      case BaseGameMotionType.CountUpLoop:
        motion = this.motion;
        this.baseGameIdleReSet = true;
        this.isWinPostIdleLot = true;
        debugDisplay('C. Win当せん時（Big未満）LOOP', motion);
        break;

      // D. Big Win以上当せん時
      case BaseGameMotionType.CountUpBig: {
        const rand = Math.floor(Math.random() * 100);
        const lot = getResultFromTable(dBigWinReaction[0]!, rand);
        if (lot === 0 || lot === 1 || lot === 3 || lot === 5) {
          debugDisplay('ERROR :D. Big Win以上当せん時', lot);
        }
        motion = MotionReactionIn[lot]!;
        this.motionType = BaseGameMotionType.CountUpBigLoop;
        this.motion = motion + 1;
        this.baseGameIdleReSet = true;
        this.isWinPostIdleLot = true;
        debugDisplay('D. Big Win以上当せん時 IN', motion, this.motion);
        break;
      }
      // D. Big Win以上当せん時 LOOP
      case BaseGameMotionType.CountUpBigLoop:
        motion = this.motion;
        this.baseGameIdleReSet = true;
        this.isWinPostIdleLot = true;
        debugDisplay('D. Big Win以上当せん時 LOOP', motion);
        break;

      // 4.1.1 キャラ決定まで
      case BaseGameMotionType.LotSelect:
        motion = this.lotSelect();
        this.motionType = BaseGameMotionType.LotSelectLoop;
        this.motion = motion;
        debugDisplay('キャラ決定まで', motion);
        break;

      // 4.1.1 キャラ決定まで
      case BaseGameMotionType.LotSelectLoop:
        motion = this.motion;
        debugDisplay('キャラ決定までLoop', motion);
        break;

      // それ以外？
      default:
        // A. 待機ループ
        this.isIdleMotion = true;
        this.setDefaultMix(true);
        motion = this.lotBaseGameWaitLoop(this.baseGameIdleReSet);
        this.motionType = BaseGameMotionType.Normal;
        break;
    }

    debugDisplay('baseGameMotionSelection決定', motion, 'date', date.toLocaleString());

    return motion;
  }

  // A. 待機ループ
  private lotBaseGameWaitLoop(isLot: boolean): number {
    if (isLot) {
      const lotTable = aBaseGameWaitLoop1;

      // A.待機ループ抽選
      const rand = Math.floor(Math.random() * 100);

      const lot = getResultFromTable(lotTable[0]!, rand);
      this.baseGameIdle = lot;
      this.baseGameIdleReSet = false;
    }
    // TODO debugDisplay(red + 'A.待機ループ', '待機', MotionTaiki[this.baseGameIdle]);
    return MotionTaiki[this.baseGameIdle]!;
  }

  // B. フィーチャ当選時
  private lotBaseGameBonusWin(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(bBonusWinReaction[0]!, rand);
    if (lot === 0 || lot === 3 || lot === 5) {
      debugDisplay('ERROR :B. フィーチャ当選時', lot);
    }
    return MotionReactionIn[lot]!;
  }

  // 3.3.2 フリースピン中のモーション選択フロー
  // TODO モーション再生終了イベントを拾ったらここへ来るように
  private avatarMotionFreeSpinGameMain(): void {
    let motion = MotionName.MIMAMORI1;
    this.isIdleMotion = false;
    this.setDefaultMix(false);

    debugDisplay(red + 'フリースピン中のアバター処理メイン this.motionType', this.motionType);

    switch (this.motionType) {
      // F. Big以上のエキスパンドWin
      case BaseGameMotionType.BigWinExpand:
        motion = this.lotBigWinExpand();
        this.motionType = BaseGameMotionType.BigWinExpandLoop;
        this.motion = motion + 1;
        this.baseGameIdleReSet = true;
        this.isWinPostIdleLot = false;
        debugDisplay('F. Big以上のエキスパンドWin', motion);
        break;

      //F. Big以上のエキスパンドWinLoop
      case BaseGameMotionType.BigWinExpandLoop:
        motion = this.motion;
        // this.motionType = BaseGameMotionType.BigWinExpandLoop;
        this.baseGameIdleReSet = true;
        this.isWinPostIdleLot = false;
        debugDisplay('F. Big以上のエキスパンドWin LOOP', motion);
        break;

      // C. Win当選時, Big未満のエキスパンドWin
      case BaseGameMotionType.CountUp: {
        const rand = Math.floor(Math.random() * 100);
        const lot = getResultFromTable(cWinReaction[0]!, rand);
        if (lot === 2 || lot === 4) {
          debugDisplay('ERROR :C. Win当選時, Big未満のエキスパンドWin', lot);
        }

        motion = MotionReactionIn[lot]!;
        this.motionType = BaseGameMotionType.CountUpLoop;
        this.motion = motion + 1;
        this.baseGameIdleReSet = true;
        this.isWinPostIdleLot = false;
        debugDisplay('C. Win当選時, Big未満のエキスパンドWin IN', motion);
        break;
      }
      // C. Win当選時, Big未満のエキスパンドWinLOOP
      case BaseGameMotionType.CountUpLoop:
        motion = this.motion;
        this.baseGameIdleReSet = true;
        this.isWinPostIdleLot = false;
        debugDisplay('C. Win当選時, Big未満のエキスパンドWin LOOP', motion);
        break;

      // D. Big Win以上当せん時
      case BaseGameMotionType.CountUpBig: {
        const rand = Math.floor(Math.random() * 100);
        const lot = getResultFromTable(dBigWinReaction[0]!, rand);
        if (lot === 0 || lot === 1 || lot === 3 || lot === 5) {
          debugDisplay('ERROR :D. Big Win以上当せん時', lot);
        }

        motion = MotionReactionIn[lot]!;
        this.motionType = BaseGameMotionType.CountUpBigLoop;
        this.baseGameIdleReSet = true;
        this.motion = motion + 1;
        this.isWinPostIdleLot = false;
        debugDisplay('D. Big Win以上当せん時 IN', motion);
        break;
      }
      // D. Big Win以上当せん時 LOOP
      case BaseGameMotionType.CountUpBigLoop:
        motion = this.motion;
        this.motionType = BaseGameMotionType.CountUpBigLoop;
        this.baseGameIdleReSet = true;
        this.isWinPostIdleLot = false;
        debugDisplay('D. Big Win以上当せん時 LOOP', motion);
        break;

      // FS終了？
      case BaseGameMotionType.TotalWin:
        if (setFreeSpinsTotalWin() / setBetAmount() >= 10) {
          // H. Total win看板(Betの10倍以上)
          motion = this.lotTotalWin10Over();
        } else {
          // G. Total win看板(Betの10倍未満)
          motion = this.lotTotalWin10Under();
        }
        this.motionType = BaseGameMotionType.TotalWinLoop;
        this.motion = motion + 1;
        this.isWinPostIdleLot = true;
        debugDisplay('FS終了 ', motion);
        break;

      // H. Total win看板 LOOP
      case BaseGameMotionType.TotalWinLoop:
        motion = this.motion;
        this.isWinPostIdleLot = true;
        debugDisplay('H. Total win看板 LOOP', motion);
        break;

      // 4.1.1 キャラ決定まで
      case BaseGameMotionType.LotSelect:
        motion = this.lotSelect();
        this.motion = motion;
        this.motionType = BaseGameMotionType.LotSelectLoop;
        debugDisplay('キャラ決定まで', motion);
        break;

      // 4.1.1 キャラ決定まで
      case BaseGameMotionType.LotSelectLoop:
        motion = this.motion;
        debugDisplay('キャラ決定までLoop', motion);
        break;

      // 4.1.2 キャラ決定時
      case BaseGameMotionType.LotConfirmed:
        motion = MotionName.REACTION3IN;
        this.motionType = BaseGameMotionType.LotConfirmedLoop;
        debugDisplay('キャラ決定時', motion);
        break;

      // 4.1.2 キャラ決定時Loop
      case BaseGameMotionType.LotConfirmedLoop:
        motion = MotionName.REACTION3LOOP;
        this.motionType = BaseGameMotionType.FreeSpin;
        debugDisplay('キャラ決定時Loop', motion);
        break;

      // フリースピンDef
      default:
        // this.motionType = BaseGameMotionType.FreeSpin;
        // this.isIdleMotion = true;
        // this.setDefaultMix(true);

        // E. watchingループ
        this.isIdleMotion = true;
        this.setDefaultMix(true);
        motion = this.lotFreeSpinGameWaitLoop(this.baseGameIdleReSet);
        this.motionType = BaseGameMotionType.FreeSpin;
        break;
    }

    debugDisplay(
      '@@@@this.motionType',
      this.motionType,
      'motion',
      motion,
      this.motionTypeNext,
      this.motionTypeNext != BaseGameMotionType.Non,
    );

    this.avatarSetAnimation(motion);

    if (this.motionTypeNext != BaseGameMotionType.Non) {
      if (this.voiceComplete) {
        this.motionType = this.motionTypeNext;
        this.motionTypeNext = BaseGameMotionType.Non;
      }
    }
  }

  // E. watchingループ
  private lotFreeSpinGameWaitLoop(isLot: boolean): number {
    if (isLot) {
      const lotTable = eFreeSpinGameWaitLoop;

      // E. watchingループ
      const rand = Math.floor(Math.random() * 100);

      const lot = getResultFromTable(lotTable[0]!, rand);
      this.baseGameIdle = lot;
      this.baseGameIdleReSet = false;
    }
    debugDisplay(red + 'E. reactionループ', '待機', MotionMimamori[this.baseGameIdle]);
    return MotionMimamori[this.baseGameIdle]!;
  }

  // F. Big以上のエキスパンドWin
  private lotBigWinExpand(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(eFreeSpinGameExpand[0]!, rand);
    if (lot === 2 || lot === 3 || lot === 5) {
      debugDisplay('ERROR :F. Big以上のエキスパンドWin', lot);
    }
    debugDisplay(red + 'F. Big以上のエキスパンドWin', 'リアクション', MotionReactionIn[lot]);
    return MotionReactionIn[lot]!;
  }

  // G. Total win看板(Betの10倍未満)
  private lotTotalWin10Under(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(gTotalWinUnderReaction[0]!, rand);
    if (lot === 2 || lot === 4) {
      debugDisplay('ERROR : G. Total win看板(Betの10倍未満)', lot);
    }
    debugDisplay(red + 'G. Total win看板(Betの10倍未満)', 'リアクション', MotionReactionIn[lot]);
    return MotionReactionIn[lot]!;
  }

  // H. Total win看板(Betの10倍以上)
  private lotTotalWin10Over(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(hTotalWinOverReaction[0]!, rand);
    if (lot === 0 || lot === 1 || lot === 3 || lot === 5) {
      debugDisplay('ERROR :H. Total win看板(Betの10倍以上)', lot);
    }
    debugDisplay(red + 'H. Total win看板(Betの10倍以上)', 'リアクション', MotionReactionIn[lot]);
    return MotionReactionIn[lot]!;
  }

  // 4.1.1 キャラ決定まで
  private lotSelect(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(hLotSelect[0]!, rand) === 0 ? MotionName.TAIKI1 : MotionName.MIMAMORI2;
    debugDisplay('4.1.1 キャラ決定まで', lot);
    return lot;
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      if (isMobileDevice()) {
        this.position.x = AVATAR_LANDSCAPE_POS_X;
      } else {
        this.position.x = AVATAR_LANDSCAPE_PC_POS_X;
      }
      this.position.y = AVATAR_LANDSCAPE_POS_Y;
      this.scale.set(AVATAR_LANDSCAPE_SCALE);
      this.isLandScape = true;
    } else {
      this.position.x = AVATAR_PORTRAIT_POS_X;
      if (isMobileDevice()) {
        this.position.y = AVATAR_PORTRAIT_MOBILE_POS_Y;
      } else {
        this.position.y = AVATAR_PORTRAIT_POS_Y;
      }
      this.scale.set(AVATAR_PORTRAIT_SCALE);
      this.isLandScape = false;
    }

    if (this.isLot) {
      if (width > height) {
        this.position.x = AVATAR_LOT_LANDSCAPE_POS_X;
      } else {
        this.position.y = AVATAR_LOT_PORTRAIT_POS_Y;
      }
    }
  }

  private setDefaultMix(isIdle: boolean): void {
    let mix = 0;
    if (isIdle && this.isIdleMotion) {
      mix = 0.1;
    }
    this.stateData.defaultMix = mix;
  }
}

export default VAvatar;
