import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setCurrentStage, setIsInTransition } from '../../gql/cache';
import { isFreeSpinsMode } from '../../utils/helper';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class FadeArea extends ViewContainer {
  private sprite: PIXI.Sprite;

  constructor() {
    super();
    this.interactive = false;
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.sprite.alpha = 0;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.START_MODE_CHANGE_FADE, this.startModeChangeFade.bind(this));
  }

  private resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }

  private startModeChangeFade(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
  }): void {
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, settings.fadeOutDuration, 0);
    fadeOut.addOnComplete(() => {
      if (!isFreeSpinsMode(settings.mode)) {
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);

        setCurrentStage(0);
      }
      setIsInTransition(false);
      eventManager.emit(EventTypes.CHANGE_MODE, settings);
    });
    const fadeIn = this.getFadeAnimation(0, settings.fadeInDuration, 1);
    fadeIn.addOnComplete(() => {
      this.interactive = false;
    });
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();

    this.interactive = true;
    setIsInTransition(true);
  }

  private getFadeAnimation(alpha: number, duration: number, begin: number): Animation {
    const animation = new Tween({
      object: this.sprite,
      duration,
      propertyBeginValue: begin,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }
}

export default FadeArea;
