import * as PIXI from 'pixi.js';

import { EventTypes, GameMode, TotalWinBannerMode } from '../../global.d';
import { SetIsCountUp, setBrokenBuyFeature, setBrokenGame, setCurrentBonus } from '../../gql/cache';
import { calcPercentage, canPressSpin, getGameModeByBonusId } from '../../utils';
import { eventManager } from '../config';

import Button from '.';

class SpinBtn extends Button {
  private count: PIXI.Text;

  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isSlotStopped: boolean;

  private isFreeSpinsWin: boolean;

  private isOpenedMessageBanner: boolean;

  private isInTransition: boolean;

  private isSceneChange: boolean;

  private isTotalWinMessageBanner: TotalWinBannerMode;

  private isOpenPopup: boolean;

  constructor() {
    super('spin', 105);
    this.interactive = !this.isDisabled;
    this.gameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonusId) : GameMode.REGULAR;
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isSlotStopped = false;
    this.isFreeSpinsWin = false;
    this.isOpenedMessageBanner = false;
    this.isTotalWinMessageBanner = TotalWinBannerMode.NON;
    this.isInTransition = false;
    this.isSceneChange = false;
    this.isOpenPopup = false;
    this.count = this.initCount();
    this.addChild(this.count);
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.count.visible = isAutoSpins;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_AUTO_SPINS_LEFT, (autoSpinsLeft: number) => {
      this.count.text = `${autoSpinsLeft}`;
      this.count.visible = !!autoSpinsLeft && this.isAutoSpins;
      this.setScaleCountText();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOTS_STOPPED, (isSlotStopped: boolean) => {
      this.isSlotStopped = isSlotStopped;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREE_SPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_OPENED_MESSAGE_BANNER, (isOpenedMessageBanner: boolean) => {
      this.isOpenedMessageBanner = isOpenedMessageBanner;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_TOTAL_WIN_MESSAGE_BANNER, (isTotalWinMessageBanner: TotalWinBannerMode) => {
      this.isTotalWinMessageBanner = isTotalWinMessageBanner;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_IN_TRANSITION, (isInTransition: boolean) => {
      this.isInTransition = isInTransition;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_SCENE_CHANGE, (isSceneChange: boolean) => {
      this.isSceneChange = isSceneChange;
      this.handleDisable();
    });

    eventManager.on(EventTypes.POPUP_BG_VISIBLE, (isOpenPopup: boolean) => {
      this.isOpenPopup = isOpenPopup;
      this.handleDisable();
    });

    eventManager.on(EventTypes.UI_VISIBLE, () => {
      this.visible = !this.visible;
    });

    eventManager.on(EventTypes.SET_IS_COUNT_UP, (_isCountUp: boolean) => {
      this.handleDisable();
    });

    eventManager.on(EventTypes.RESIZE_GAME_CONTAINER, this.handlePosition.bind(this));

    eventManager.on(EventTypes.SET_BROKEN_BUY_FEATURE, (_isBroken: boolean) => {
      this.handleDisable();
    });
  };

  private initCount = (): PIXI.Text => {
    const count = new PIXI.Text('', {
      fontSize: 20,
      whiteSpace: 'normal',
      fontWeight: '700',
      align: 'center',
    });
    count.visible = false;
    count.anchor.set(0.5);
    count.y = 0;
    count.x = 0;

    return count;
  };

  private handleUpdateIntent = (): void => {
    this.updateIntent(this.isSlotBusy || this.isAutoSpins ? 'stop' : 'spin');
    if (this.isSlotBusy || this.isAutoSpins) this.updateIntent(!this.isOpenedMessageBanner ? 'stop' : 'spin');
  };

  private handleDisable = (): void => {
    this.setDisable(
      !canPressSpin({
        gameMode: this.gameMode,
        isFreeSpinsWin: this.isFreeSpinsWin,
        isSlotBusy: this.isSlotBusy,
        isSlotStopped: this.isSlotStopped,
        isSpinInProgress: this.isSpinInProgress,
        isOpenedMessageBanner: this.isOpenedMessageBanner,
        isTotalWinMessageBanner: this.isTotalWinMessageBanner,
        isInTransition: this.isInTransition,
        isCountUp: SetIsCountUp(),
        isSceneChange: this.isSceneChange,
        isBrokenBuyFeature: setBrokenBuyFeature(),
        isOpenPopup: this.isOpenPopup,
      }),
    );
  };

  private handleClick = (): void => {
    if (!this.isDisabled) {
      eventManager.emit(EventTypes.TOGGLE_SPIN);
    }
  };

  private setScaleCountText = () => {
    const scaleValue = this.width / (110 / 100);
    this.count.scale.set(scaleValue / 100);
  };

  public override handlePosition(): void {
    this.setScaleCountText();
    let x = calcPercentage(this.applicationSize.width, 89);
    let y = calcPercentage(this.applicationSize.height, 91);
    this.setSize(calcPercentage(this.applicationSize.width, 8.2));

    if (this.isPortraitMode) {
      this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 45));
      x = calcPercentage(this.applicationSize.width, 50);
      y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 57);
    }

    if (this.isLandscapeMode) {
      this.setSize(calcPercentage(this.applicationSize.height, 21));
      x = calcPercentage(this.applicationSize.width, 91);
      y = calcPercentage(this.applicationSize.height, 50);
    }

    this.x = x;
    this.y = y;
  }
}

export default SpinBtn;
