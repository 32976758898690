import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { getAnimationSelectionTableLot } from '../anticipation';
import { AnimationType, baseGamePhoenix } from '../anticipation/table';
import { ISongs, SlotId, mappedAudioSprites } from '../config';
import {
  BetBonusReward,
  BetReward,
  BgmSoundTypes,
  BonusStatus,
  EventTypes,
  GameMode,
  ISettledBet,
  ISettledBet2,
  ReelId,
  UserBonus,
  bonusIdToReelsId,
  freeRoundBonusId,
  reelSets,
} from '../global.d';
import {
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setBrokenGameReelSet,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setCurrentStage,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFreeRoundsBonus,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSpSymbolLotOpening,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { IAlbumInfo, ISlotConfig } from '../gql/d';
import { ReelSetType, getUserBonuses, isStoppedGql, slotBetGql2 } from '../gql/query';
import { formatNumber, getCoinAmount, getGameModeByBonusId, nextTick, normalizeCoins, showCurrency } from '../utils';
import {
  getExpandCount,
  getExpandSymbolCount,
  getExpandSymbolPosition,
  getScatterCount,
  getScatterPosition,
  getSlotId,
  getSlotIdNumber,
  getSpinResult5x3,
  getUserAlbumInfo,
  isBaseGameMode,
  isBuyFeatureMode,
  isFreeSpinsMode,
  setUserAlbumInfo,
} from '../utils/helper';

import AlbumMenuBtn from './album/albumMenuBtn';
import AlbumBtnIcon from './album/albumMenuBtnIcon';
import Animation from './animations/animation';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BgmControl from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE,
  ANTICIPATION_SYMBOLS_ID,
  FREE_SPINS_FADE_IN_DURATION,
  FREE_SPINS_FADE_OUT_DURATION,
  FREE_SPINS_TIME_OUT_BANNER,
  REELS_AMOUNT,
  SlotMachineState,
  eventManager,
  spSymbolList,
} from './config';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import FreeRoundsPopup from './freeRoundsBonus/freeRoundsPopup';
import { FreeRoundsEndPopup } from './freeRoundsBonus/freeRoundsPopupEnd';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import SceneChange from './sceneChange/sceneChange';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import SlotsAnimationContainer from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotConfig;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private multiplierOld = 0;

  private heartOld = 0;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(application, slotConfig, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  private phoenix: Phoenix;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBuyFeatureIcon: PIXI.Container;

  public infoAlbumIcon: PIXI.Container;

  public sceneChange: PIXI.Container;

  public album: IAlbumInfo;

  private freeSpinsBonusReelId: string | undefined;

  private constructor(
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();
    this.album = this.getAlbumInfo();

    let startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;

    let reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;

    if (startPosition.length === 0) {
      startPosition = [0, 0, 0, 0, 0];
      reelSet = slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;
    }

    if (setBrokenBuyFeature()) {
      setIsSlotBusy(true);
      eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
      nextTick(() => {
        eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
        if (this.state === SlotMachineState.IDLE) eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
      });
    }
    if (setBrokenGame()) {
      startPosition = setPrevReelsPosition();
      reelSet = slotConfig.reels.find((reelSet) => reelSet.id === setBrokenGameReelSet())!;
    }
    setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));

    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.tintContainer = new TintContainer();
    const spinResult = getSpinResult5x3({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet,
      icons: slotConfig.icons,
    });

    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.background = new Background();
    this.application.stage.addChild(this.background);
    this.application.stage.sortableChildren = true;

    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.sceneChange = new SceneChange();
    this.gameView = new GameView({
      winSlotsContainer: new SlotsAnimationContainer(),
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });
    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);

    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, true);

    this.initBuyFeature(slotConfig.lines, this.gameView);
    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();
    this.infoAlbumIcon = new AlbumBtnIcon();

    this.application.stage.addChild(this.bottom);
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);

    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.fadeArea);
    if (setBrokenGame()) {
      this.onBrokenGame();
    } else if (setIsFreeRoundsBonus() && !setBrokenBuyFeature()) {
      eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
    }
    this.application.stage.addChild(this.sceneChange);

    this.phoenix = new Phoenix();
    this.application.stage.addChild(this.phoenix);
  }

  private getAlbumInfo(): IAlbumInfo {
    const b = getUserAlbumInfo('B', 0);
    const c = getUserAlbumInfo('C', 0);
    const d = getUserAlbumInfo('D', 0);
    const e = getUserAlbumInfo('E', 0);
    const f = getUserAlbumInfo('F', 0);
    const g = getUserAlbumInfo('G', 0);
    const h = getUserAlbumInfo('H', 0);
    const i = getUserAlbumInfo('I', 0);
    const j = getUserAlbumInfo('J', 0);
    return { b, c, d, e, f, g, h, i, j };
  }

  private initBuyFeature(lines: number[][], view: GameView): void {
    view.addChild(
      new BuyFeatureBtn(),
      new AlbumMenuBtn(),
      new Backdrop(),
      new BuyFeaturePopup(lines),
      new BuyFeaturePopupConfirm(),
      new FreeRoundsPopup(),
      new FreeRoundsEndPopup(),
    );
  }

  private onBrokenGame(): void {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonus.id);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().reelSetId);

    let currentRound = 0;

    if (!(setUserLastBetResult().reelSetId === ReelId.REGULAR)) {
      this.multiplierOld = setUserLastBetResult().data.features.gameRoundStore.multiplier;
      this.heartOld = setUserLastBetResult().data.features.gameRoundStore.heart;
      currentRound = setUserLastBetResult().data.features.gameRoundStore.playedFS;
      setCurrentBonus({ ...setCurrentBonus(), currentRound: currentRound });
    }

    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }

    this.freeSpinsBonusReelId = bonusIdToReelsId[setCurrentBonus().bonusId!]!;

    this.nextResult = setNextResult();

    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
    if (
      setUserLastBetResult().reelSetId === ReelId.REGULAR ||
      setUserLastBetResult().reelSetId === ReelId.BUY_FEATURE
    ) {
      setIsSpSymbolLotOpening(true);
      eventManager.emit(EventTypes.SP_SYMBOL_LOT_START);
    } else {
      setCurrentStage(1);
      BgmControl.playBgm(BgmSoundTypes.BB);
      eventManager.emit(EventTypes.FREE_SPINS_DISPLAY_SHOW, true);
      this.setState(SlotMachineState.IDLE);
    }

    // TODO TotalWin
    // if (setCurrentFreeSpinsTotalWin()) {
    //   eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    // } else {
    //   eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    // }
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.Win_Loop });
      }
    });

    eventManager.addListener(EventTypes.SET_SLOT_BUSY_DISABLE, () => {
      this.isSlotBusyCallback();
    });

    eventManager.addListener(EventTypes.FREE_SPIN, this.spin.bind(this));

    eventManager.addListener(EventTypes.SET_STATE, this.setState.bind(this));

    eventManager.addListener(EventTypes.START_BUY_FEATURE_ROUND, this.startBuyFeature.bind(this));

    eventManager.addListener(EventTypes.END_EXPAND_SYMBOL, this.expandEnd.bind(this));

    eventManager.addListener(EventTypes.START_EXPAND_SCENE, this.expandWin.bind(this));
  }

  private startBuyFeature(): void {
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.BUY_FEATURE,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[GameMode.REGULAR]!,
    });
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);

      if (settings.mode === GameMode.REGULAR) {
        setReelSetId(settings.reelSetId);
        const reelSet = setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId);
        const spinResult = getSpinResult5x3({
          reelPositions: settings.reelPositions.slice(0, 5),
          reelSet: reelSet!,
          icons: setSlotConfig().icons,
        });
        setPrevReelsPosition(settings.reelPositions.slice(0, 5));

        this.miniPayTableContainer.setSpinResult(spinResult);
        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: reelSet!,
          reelPositions: settings.reelPositions,
        });
        eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, false);

        setCurrentFreeSpinsTotalWin(0);
        this.isSpinInProgressCallback();

        // if (setFreeRoundsTotalWin() > 0) {
        //   eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
        // }
      }
    }
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
        totalRound: 0,
      });
      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
      this.freeSpinsBonusReelId = undefined;
      this.heartOld = 0;
      this.multiplierOld = 0;
    } else if (isFreeSpinsMode(settings.mode)) {
      const bonus = this.getFreeSpinBonus();

      setCurrentBonusId(setCurrentBonus().id);
      this.freeSpinsBonusReelId = SlotMachine.getInstance().getFreeSpinBonus()?.bonus.reelSetId;

      // todo replace with normal error
      if (!bonus) throw new Error('Something went wrong');

      setCurrentBonus({ ...bonus, currentRound: 0, totalRound: 0, isActive: true });
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
    if (this.nextResult) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
    }
  }

  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.FREE_SPIN_B,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[GameMode.FREE_SPIN_B]!,
      fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
      fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
    });
    setTimeout(() => {
      if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      } else {
        eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      }
    }, FREE_SPINS_FADE_IN_DURATION);
  }

  private async endFreeSpins(isFrb: boolean): Promise<void> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    const { betId } = res.data.userBonuses[0]!;
    const bet = await client.query<ISettledBet2>({
      query: slotBetGql2,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });

    const { reelPositions, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      reelSetId: bet.data.bet.reelSetId,
    };

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());

    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();

    this.setEndingDisplay(reelPositions, reelSetId, isFrb);
    setBrokenGame(false);
  }

  private setEndingDisplay(reelPositions: number[], reelSetId: string, isFrb: boolean): void {
    const callback = () => {
      if (setIsShowSoundToast()) {
        setIsShowSoundToast(false);
        AudioApi.changeRestriction(
          false,
          [],
          () => {},
          () => {
            handleChangeRestriction();
          },
        );
      }

      eventManager.emit(EventTypes.SCENE_CHANGE_START, false, reelPositions, reelSetId);

      setTimeout(() => {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency())),
        );

        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, setIsContinueAutoSpinsAfterFeature());

        if (setIsFreeRoundsBonus()) {
          setTimeout(() => {
            setFreeRoundsTotalWin(setFreeRoundsTotalWin() + setFreeSpinsTotalWin());
            if (isFrb && !setFreeRoundsBonus().isActive) {
              eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
            } else if (!isFrb && setFreeRoundsBonus().rounds !== setFreeRoundsBonus().currentRound) {
              setStressful({
                show: true,
                type: 'network',
                message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
                callback: () => {
                  eventManager.emit(EventTypes.END_FREE_ROUND_BONUS);
                  eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
                },
              });
            }
          }, FREE_SPINS_FADE_IN_DURATION);
        }
      }, FREE_SPINS_FADE_IN_DURATION);
    };

    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    eventManager.emit(EventTypes.SET_SLOT_BUSY_DISABLE);
    eventManager.emit(EventTypes.BONUS_END);

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )} `,
        preventDefaultDestroy: true,
        callback,
        title: i18n.t('TotalWinTitle'),
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency()))}`,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: i18n.t('TotalWinTitle'),
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isNumber(arg: any): arg is number {
    return typeof arg === 'number';
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(_current: number): void {}

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          this.removeErrorHandler();
          this.dynamicReelSetChange();
          let slotId = SlotId.J;
          if (this.nextResult.bet.data.features.gameRoundStore != undefined) {
            slotId = getSlotId(this.nextResult.bet.data.features.gameRoundStore.id);
          }
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.nextResult.bet.result.reelPositions,
            getScatterCount(this.nextResult.bet.result.spinResult),
            getExpandCount(
              this.nextResult.bet.result.spinResult,
              this.nextResult.bet.data.features.gameRoundStore != undefined ? getSlotId(slotId) : SlotId.WL,
            ),
            getSlotId(slotId),
            this.getAnticipationReelId(this.nextResult.bet.result.spinResult),
          );
        }
        eventManager.emit(EventTypes.SETUP_BET_RESULT, this.nextResult);
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      if (isFreeSpinsMode(setGameMode())) {
        const bonus = setCurrentBonus();
        if (bonus.currentRound === 0 && bonus.totalRound > 0) {
          // AudioApi.play({ type: ISongs.FsReset });
        }
        bonus.currentRound += 1;
        bonus.totalRound += 1;

        eventManager.emit(
          EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT,
          setCurrentBonus().rounds,
          bonus.currentRound,
          false,
        );
        setCurrentBonus(bonus);
      }
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      const spinAnimation = this.getSpinAnimation(!isFreeSpinsMode(setGameMode()) && !!isTurboSpin);

      spinAnimation.start();

      this.setState(SlotMachineState.SPIN);
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            if (isFreeSpinsMode(setGameMode())) {
              // this.updateFreeSpinsAmount(setCurrentBonus().currentRound);
            }
            this.removeErrorHandler();
            this.dynamicReelSetChange();
            let slotId = SlotId.J;
            if (this.nextResult.bet.data.features.gameRoundStore != undefined) {
              slotId = getSlotId(this.nextResult.bet.data.features.gameRoundStore.id);
            }
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              getScatterCount(this.nextResult.bet.result.spinResult),
              getExpandCount(this.nextResult.bet.result.spinResult, slotId),
              slotId,
              this.getAnticipationReelId(this.nextResult.bet.result.spinResult),
            );
            eventManager.emit(EventTypes.SETUP_BET_RESULT, this.nextResult);
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  public getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';
    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN';
    })?.userBonus;
  }

  private onCountUpEnd(isExpand: boolean, value?: number): void {
    const freeSpinsBonus = this.getFreeSpinBonus();
    const mode = setGameMode();
    if (freeSpinsBonus && isFreeSpinsMode(mode)) {
      setCurrentBonus({
        ...freeSpinsBonus,
        isActive: true,
        currentRound: 0,
        totalRound: setCurrentBonus().totalRound,
      });
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
      if (setFreeRoundsBonus().isActive && setFreeRoundsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      } else if (getCoinAmount(this.nextResult!, true, false) > 0 && !isExpand) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, value!);
      }
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      this.scatterHeart();
      //this.setState(SlotMachineState.IDLE);
    } else if (freeSpinsBonus && !isFreeSpinsMode(mode)) {
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
      setCurrentBonus({
        ...freeSpinsBonus,
        isActive: true,
        currentRound: 0,
        totalRound: 0,
      });
      setWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setIsFreeSpinsWin(true);
      const delay = Tween.createDelayAnimation(500);
      delay.addOnComplete(() => {
        eventManager.emit(EventTypes.SCENE_CHANGE_START, true);
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      });
      delay.start();
    } else if (!freeSpinsBonus && isFreeSpinsMode(mode)) {
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
      if (setFreeRoundsBonus().isActive && setFreeRoundsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      } else if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
      this.setState(SlotMachineState.IDLE);
    } else {
      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.REGULAR,
        reelPositions: [0, 0, 0, 0, 0],
        reelSetId: reelSets[GameMode.REGULAR]!,
      });
      setWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      if (isFreeSpinsMode(mode)) {
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        if (setFreeRoundsBonus().isActive && setFreeRoundsTotalWin() > 0) {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
        } else {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
        }
      }
      this.setState(SlotMachineState.IDLE);
    }
  }

  private getAlbumNum(reelSetId: string): number {
    let rtn = 0;
    switch (reelSetId) {
      case 'B':
        rtn = this.album.b;
        break;
      case 'C':
        rtn = this.album.c;
        break;
      case 'D':
        rtn = this.album.d;
        break;
      case 'E':
        rtn = this.album.e;
        break;
      case 'F':
        rtn = this.album.f;
        break;
      case 'G':
        rtn = this.album.g;
        break;
      case 'H':
        rtn = this.album.h;
        break;
      case 'I':
        rtn = this.album.i;
        break;
      case 'J':
        rtn = this.album.j;
        break;
    }
    return rtn;
  }

  private scatterHeart(): void {
    let scatterCount = getScatterCount(this.nextResult!.bet.result.spinResult);
    let scatterPosition = getScatterPosition(this.nextResult!.bet.result.spinResult);
    const spSlotId = spSymbolList[this.freeSpinsBonusReelId!];

    eventManager.emit(
      EventTypes.SET_METER,
      setNextResult()?.bet.data.features.gameRoundStore.multiplier!,
      setNextResult()?.bet.data.features.gameRoundStore.heart!,
    );

    if (
      (this.multiplierOld === setNextResult()?.bet.data.features.gameRoundStore.multiplier! &&
        this.heartOld === setNextResult()?.bet.data.features.gameRoundStore.heart!) ||
      spSlotId === SlotId.C ||
      spSlotId === SlotId.E ||
      spSlotId === SlotId.F ||
      spSlotId === SlotId.I
    ) {
      scatterCount = [0, 0, 0, 0, 0];
      scatterPosition = [0, 0, 0, 0, 0];
    }
    eventManager.emit(EventTypes.SCATTER_HEART_STOCK, scatterCount, scatterPosition);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.nextResult!.bet.result.spinResult, false);
  }

  private expandWin(): void {
    const spSlotId = spSymbolList[this.freeSpinsBonusReelId!];
    const expandSymbolCount = getExpandSymbolCount(this.nextResult!.bet.result.spinResult, spSlotId);
    const expandSymbolPosition = getExpandSymbolPosition(this.nextResult!.bet.result.spinResult, spSlotId);
    let scatterCount = getScatterCount(this.nextResult!.bet.result.spinResult);
    let isMax = false;

    if (
      (this.multiplierOld === setNextResult()?.bet.data.features.gameRoundStore.multiplier! &&
        this.heartOld === setNextResult()?.bet.data.features.gameRoundStore.heart!) ||
      spSlotId === SlotId.C ||
      spSlotId === SlotId.E ||
      spSlotId === SlotId.F ||
      spSlotId === SlotId.I
    ) {
      scatterCount = [0, 0, 0, 0, 0];
      isMax = true;
    }

    this.multiplierOld = setNextResult()?.bet.data.features.gameRoundStore.multiplier!;
    this.heartOld = setNextResult()?.bet.data.features.gameRoundStore.heart!;

    if (spSlotId! <= SlotId.E) {
      if (Math.max(...expandSymbolCount) >= 2) {
        eventManager.emit(
          EventTypes.START_EXPAND_SYMBOL,
          expandSymbolCount,
          expandSymbolPosition,
          getSlotIdNumber(spSlotId!),
          isMax,
        );
      } else if (Math.max(...scatterCount) >= 3) {
        eventManager.emit(EventTypes.HEART_SHOT);
      } else {
        this.setState(SlotMachineState.IDLE);
      }
    } else if (spSlotId! > SlotId.E) {
      if (Math.max(...expandSymbolCount) >= 3) {
        eventManager.emit(
          EventTypes.START_EXPAND_SYMBOL,
          expandSymbolCount,
          expandSymbolPosition,
          getSlotIdNumber(spSlotId!),
          isMax,
        );
      } else if (Math.max(...scatterCount) >= 3) {
        eventManager.emit(EventTypes.HEART_SHOT);
      } else {
        this.setState(SlotMachineState.IDLE);
      }
    }
  }

  private expandEnd(): void {
    this.setState(SlotMachineState.IDLE);
  }

  private dynamicReelSetChange(): void {
    if (setReelSetId() !== reelSets[setGameMode()]) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === reelSets[setGameMode()])!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelSets[setGameMode()]);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        if (spinResult[j + REELS_AMOUNT * 0]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT * 1]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT * 2]!.id === symbolId) currentCount += 1;

        if (currentCount >= count!) minReelId = Math.min(minReelId, j);
      }
    });

    if (setNextResult()!.bet.result.winCoinAmount / setBetAmount() >= 500) {
      const AnimationPtn = getAnimationSelectionTableLot(setNextResult()!.bet.id, baseGamePhoenix);
      if (AnimationPtn === AnimationType.PHOENIX) {
        eventManager.emit(EventTypes.PHOENIX_START);
      }
    }

    return minReelId;
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult5x3({
      reelPositions: result.bet.result.reelPositions.slice(0, REELS_AMOUNT),
      reelSet: setSlotConfig().reels.find((reelSet) => reelSet.id === result.bet.reelSet.id)!,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;

    setPrevReelsPosition(result.bet.result.reelPositions.slice(0, REELS_AMOUNT));
    this.nextResult = result;
    setNextResult(result);

    const freeSpinsBonus = this.getFreeSpinBonus();
    if (freeSpinsBonus && !isFreeSpinsMode(setGameMode())) {
      this.album = this.getAlbumInfo();
      setUserAlbumInfo(
        setNextResult()?.bet.data.features.gameRoundStore.id!,
        this.getAlbumNum(setNextResult()?.bet.data.features.gameRoundStore.id!),
      );
    }

    if (setFreeRoundsBonus().isActive) {
      setFreeRoundsTotalWin(setFreeRoundsTotalWin() + result.bet.result.winCoinAmount);
      if (!setCurrentBonus().isActive) {
        setFreeRoundsBonus({ ...setFreeRoundsBonus(), currentRound: setFreeRoundsBonus().currentRound + 1 });
        eventManager.emit(
          EventTypes.UPDATE_FREE_ROUNDS_LEFT,
          setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound,
        );
      }
    }
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult.balance.placed);
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    if (setBrokenBuyFeature()) {
      setBrokenBuyFeature(false);
    }
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(this.nextResult!.bet.result.spinResult);
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length - this.reelsContainer.reels[x]!.position + y - 1) %
        this.reelsContainer.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(_width: number, _height: number): void {}

  private setState(state: SlotMachineState): void {
    this.state = state;

    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinsMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  public hasWin() {
    if (this.nextResult === null) {
      return false;
    }
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature(),
    );

    if (state === SlotMachineState.IDLE) {
      // 本来はCountUpEndに入れたいがNormalWinとExpandWinで2回通るのでここに変更
      if (this.nextResult) {
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      }
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      if (isFreeSpinsMode(setGameMode())) {
        if (
          setCurrentBonus().isActive &&
          this.nextResult?.bet.data.features.gameRoundStore.countFS! <=
            this.nextResult?.bet.data.features.gameRoundStore.playedFS!
        ) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });

          if (setIsFreeRoundsBonus()) {
            client
              .query<{ userBonuses: UserBonus[] }>({
                query: getUserBonuses,
                variables: {
                  input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
                },
                fetchPolicy: 'network-only',
              })
              .then((bonuses) => {
                const frbBonus = bonuses.data.userBonuses.find((e) => e.bonusId === freeRoundBonusId) as UserBonus;
                this.endFreeSpins(!!frbBonus);
              });
          } else {
            this.endFreeSpins(false);
          }
        } else {
          this.skipAnimations();
          setTimeout(
            () => {
              const bonus = setCurrentBonus();
              if (bonus.currentRound === 0 && bonus.totalRound > 0) {
                AudioApi.play({ type: ISongs.FsReset });

                eventManager.emit(
                  EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT,
                  setCurrentBonus().rounds,
                  bonus.currentRound,
                  false,
                );
                const delay = Tween.createDelayAnimation(1000);
                delay.addOnComplete(() => {
                  eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
                });
                delay.start();
              } else {
                eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
              }
            },
            setCurrentBonus().totalRound === 0 ? 0 : 500,
          );
        }
      } else if (setFreeRoundsBonus().isActive && setFreeRoundsBonus().rounds === setFreeRoundsBonus().currentRound) {
        eventManager.emit(EventTypes.END_FREE_ROUND_BONUS);
        eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      }

      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.getFreeSpinBonus() && (isBaseGameMode(setGameMode()) || isBuyFeatureMode(setGameMode()))) {
        const jingleDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.FS_Trigger].duration);
        jingleDelay.addOnStart(() => {
          AudioApi.play({ type: ISongs.FS_Trigger, stopPrev: true });
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      eventManager.emit(EventTypes.SPIN_END);
      if (this.hasWin()) {
        if (Math.max(...getScatterCount(this.nextResult!.bet.result.spinResult)) >= 3) {
          eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false, true, false);
        } else {
          eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false, false, false);
        }
      } else {
        this.onCountUpEnd(true);
      }
    }
  }

  public getAlbumInfoData(): void {
    this.album = this.getAlbumInfo();
  }
}

export default SlotMachine;
