import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import {
  LOADER_SPRITE_TEXTURES,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  ISettledBet,
  IUserBalance,
  ReplayFreeSpinBets,
  UserBonus,
  bonusIds,
  reelSets,
} from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setBrokenBuyFeature,
  setBrokenGame,
  setBrokenGameReelSet,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsEnabledSpaceSpin,
  setIsFreeRoundsBonus,
  setIsMiniPayTable,
  setIsMobile,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setPrevReelsPosition,
  setProgress,
  setReelSetId,
  setReplayBet,
  setReplayFreeSpinBets,
  setReplayFreeSpinReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client, { webSocketClient } from '../../gql/client';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  ReelSetType,
  betsByInitialRoundId,
  configGql,
  getBonuses,
  getProgressGql,
  getSlotGql,
  getUserBonuses,
  getUserGql,
  slotBetGql,
  slotHistoryGql,
  userBonusBetsGql,
} from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import {
  findSubstituteCoinAmount,
  isFreeRoundBonus,
  isMobileDevice,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  queryParams,
} from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const getCoinValue = (slotConfig: ISlotData) => {
  if (setBrokenGame()) {
    return setCurrentBonus().coinValue;
  }
  return slotConfig.clientSettings.coinValues.find((e) => e.code === setCurrency())?.variants[0];
};

const getCoinAmount = (slotConfig: ISlotData) => {
  const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
  let coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slotConfig.clientSettings.coinAmounts.default);

  if (setBrokenGame()) {
    coinAmount = setCurrentBonus().coinAmount;
  }
  return coinAmount;
};

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(true);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
      webSocketClient.close(false);
    },
  });

  useEffect(() => {
    const getReplayBetFreeSpins = async () => {
      const replayBetFreeSpins = await client.query<
        {
          betsByInitialRoundId: ReplayFreeSpinBets[];
        },
        { initialRoundId: string }
      >({
        query: betsByInitialRoundId,
        variables: {
          initialRoundId: setReplayBet(),
        },
      });

      const replayData = replayBetFreeSpins.data.betsByInitialRoundId;

      if (replayData.length) {
        const replayBetIds = replayData.map((e) => e.id);
        if (replayData.length > 1) {
          replayBetIds.splice(0, 1);
          setReplayBet(replayBetIds[0]);
          setReplayFreeSpinReelSetId(replayData[1]!.reelSetId);
          setReplayFreeSpinBets(replayBetIds);
        } else {
          setReplayFreeSpinReelSetId(replayData[0]!.reelSetId);
        }
      }
    };

    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBonusGame = async () => {
      const activeUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      // its locally for testing.
      // ------------------------------------------------------
      // const testData = {
      //   data: {
      //     userBonuses: [
      //       {
      //         betId: freeRoundBonusId,
      //         bonus: {
      //           id: freeRoundBonusId,
      //           coinAmount: 1,
      //           type: 'FREE_BET_ROUND',
      //         },
      //         bonusId: freeRoundBonusId,
      //         coinAmount: 1,
      //         coinValue: 100,
      //         data: {},
      //         id: freeRoundBonusId,
      //         rounds: 5,
      //         roundsPlayed: 0,
      //         status: 'ACTIVE',
      //         totalWinAmount: 0,
      //       },
      //     ],
      //   },
      //   loading: false,
      //   networkStatus: 7,
      // };

      // const activeUserBonusData: ApolloQueryResult<{
      //   userBonuses: UserBonus[];
      // }> = JSON.parse(JSON.stringify(testData));
      // ------------------------------------------------------

      if (activeUserBonusData.data.userBonuses.length > 0) {
        const bonus = activeUserBonusData.data.userBonuses.find((e) => !isFreeRoundBonus(e.bonusId));
        const frbBonus = activeUserBonusData.data.userBonuses.find((e) => isFreeRoundBonus(e.bonusId));

        if (frbBonus) {
          setIsFreeRoundsBonus(true);
          setFreeRoundsBonus({
            ...frbBonus,
            isActive: false,
            currentRound: frbBonus.roundsPlayed,
          });
          if (frbBonus.totalWinAmount) {
            setFreeRoundsTotalWin(frbBonus.totalWinAmount);
          }
        }
        if (bonus && bonus.bonusId === bonusIds[GameMode.BUY_FEATURE]) {
          const specialBonus = activeUserBonusData.data.userBonuses.find((ub) => ub.bonus.type === 'SPECIAL_ROUND');
          setBrokenBuyFeature(true);
          eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
          setCurrentBonusId(activeUserBonusData.data.userBonuses[0]!.id);
          if (specialBonus) {
            setCurrentBonus({
              ...specialBonus,
              isActive: true,
            });
          }
        } else {
          const freeSpinsBonus = activeUserBonusData.data.userBonuses.find((ub) => ub.bonus.type === 'FREE_SPIN');

          // TODO TotalWin
          // const fsTotalAmount = freeSpinsBonus?.totalWinAmount
          //   ? freeSpinsBonus?.totalWinAmount / freeSpinsBonus.coinValue
          //   : 0;

          if (freeSpinsBonus) {
            setBrokenGame(true);

            setCurrentBonus({
              ...freeSpinsBonus,
              isActive: true,
            });
            setCurrentFreeSpinsTotalWin(0);
            // setCurrentFreeSpinsTotalWin(fsTotalAmount);  // TODO TotalWin
            const userBonusBetsData = await client.query<{
              bets: ISlotHistoryData;
            }>({
              query: userBonusBetsGql,

              variables: {
                input: {
                  filter: {
                    userBonusId: setCurrentBonus().id,
                  },
                },
              },
              fetchPolicy: 'network-only',
            });

            setCurrentBonus({
              ...setCurrentBonus(),
            });

            if (userBonusBetsData.data.bets.pageInfo.count === 0) {
              const lastBetRes = { ...setUserLastBetResult() };
              lastBetRes.reelSetId = reelSets[setGameMode()]!;
              lastBetRes.result = {
                ...lastBetRes.result,
                reelPositions: [0, 0, 0, 0, 0],
              };
            }

            if (setCurrentBonus().id != '') {
              const res = await client.query<{
                userBonuses: UserBonus[];
              }>({
                query: getUserBonuses,
                variables: { input: { id: setCurrentBonus().id } },
                fetchPolicy: 'network-only',
              });
              const { betId } = res.data.userBonuses[0]!;
              const bet = await client.query<ISettledBet>({
                query: slotBetGql,
                variables: { input: { id: betId } },
                fetchPolicy: 'network-only',
              });

              setCurrentBonusId(setCurrentBonus().id);
              setCurrentBonus({
                ...setCurrentBonus(),
                rounds: bet.data.bet.data.features.gameRoundStore.countFS,
                currentRound: bet.data.bet.data.features.gameRoundStore.playedFS,
                totalRound: 0,
              });

              if (setReplayBet()) {
                const lastBetData = setUserLastBetResult();
                // for updated data
                const playedFS =
                  lastBetData.data.features.gameRoundStore.playedFS > 0
                    ? lastBetData.data.features.gameRoundStore.playedFS - 1
                    : lastBetData.data.features.gameRoundStore.playedFS;
                setUserLastBetResult({
                  ...lastBetData,
                  data: { features: { gameRoundStore: { ...lastBetData.data.features.gameRoundStore, playedFS } } },
                });
              }

              setBrokenGameReelSet(setUserLastBetResult().reelSetId);
              setPrevReelsPosition(setUserLastBetResult().result.reelPositions);
              setCurrentBonus({ ...setCurrentBonus(), reelSetId: bet.data.bet.reelSetId });
            }
          }
        }
      }
    };

    const getSlotData = async () => {
      const slotData = await client.query<{ slot: ISlotData }>({
        query: getSlotGql,
        variables: { input: { id: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      const { slot } = slotData.data;

      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        icons: slot.icons,
        reels: slot.reels,
        winLines: slot.lines,
        lines: slot.lines,
        lineSet: slot.lineSets[0]!,
        settings: slot.settings,
      });
      // todo add logic to pick gamemode and reelsetid
      setReelSetId(
        slot.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)?.id || reelSets[GameMode.REGULAR],
      );

      let coinValue = getCoinValue(slot);
      let coinAmount = getCoinAmount(slot);
      if (setBrokenBuyFeature()) {
        coinValue = setCurrentBonus().coinValue;
        coinAmount = setCurrentBonus().coinAmount;
      }
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * slot.lineSets[0]!.coinAmountMultiplier);
    };

    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        setIsMobile(isMobileDevice());
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });
        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data?.auth.sessionId as string);
        rebuildStorageCache<IConfig>('config', {
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        if (queryParams.has('replayBetId')) {
          await getReplayBetFreeSpins();
        }
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBonusGame();
        await getSlotData();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(
          [...LOADER_TEXTURES, ...LOADER_SPRITE_TEXTURES, ...SPINE_LOADER_TEXTURES(setIsMobile())],
          process.env.PUBLIC_URL,
        );
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async () => {
        AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          restricted: true,
          isSoundEnabled: isSoundOn,
          onSuspended: setIsSuspended,
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
        }).then(() => {
          eventManager.emit(
            EventTypes.SOUND_INITIALIZED,
            AudioApi.isRestricted && !(!AudioApi.restrictionChangedOnIntroScreen && !setIsSoundOn()),
          );
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles['loadScreenWrapper']}>
      <div className={styles['logo']}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles['companyLogo']}
        />
      </div>
      <ProgressBar
        className={styles['progressBar']!}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
